import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import { makeStyles } from "@material-ui/styles";
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  Typography
} from "@material-ui/core";
import Img from "gatsby-image";

import { MdClose } from "react-icons/md";

const useStyles = makeStyles(theme => ({
  drawer: {
    minWidth: 300
  },
  ul: {
    padding: 0,
    listStyle: "none",
    "& li": {
      marginBottom: theme.spacing(0.5)
    },
    "& a": {
      textDecoration: "none"
    },
    "& a:hover": {
      textDecoration: "underline"
    }
  }
}));

export default ({ open, onClose }) => {
  const classes = useStyles();

  return (
    <StaticQuery
      query={graphql`
        {
          site {
            siteMetadata {
              title
              components {
                appbar {
                  games {
                    title
                    url
                    icon
                  }
                }
              }
            }
          }
        }
      `}
      render={({
        site: {
          siteMetadata: {
            title,
            components: {
              appbar: { games }
            }
          }
        }
      }) => (
        <Drawer
          classes={{ paper: classes.drawer }}
          open={open}
          onClose={onClose}
        >
          <Box display="flex" flexDirection="column" padding={1}>
            <Box display="flex" alignItems="center">
              <Box flexGrow={1} paddingLeft={1}>
                <Typography
                  color="textPrimary"
                  variant="h6"
                  style={{
                    fontFamily:
                      "Work Sans, -apple-system, BlinkMacSystemFont, Reem Kufi, sans-serif"
                  }}
                >
                  {title}
                </Typography>
                <Typography
                  color="textPrimary"
                  variant="h6"
                  style={{
                    fontFamily:
                      "Work Sans, -apple-system, BlinkMacSystemFont, Reem Kufi, sans-serif"
                  }}
                >
                  GAMES:
                </Typography>
              </Box>
              <IconButton onClick={onClose}>
                <MdClose />
              </IconButton>
            </Box>
            <List>
              {games.map(game => {
                return (
                  <ListItem
                    button
                    key={game.title}
                    to={game.url}
                    component={Link}
                  >
                    <Img fluid={game.icon} />

                    <Typography variant="h3">{game.title}</Typography>
                  </ListItem>
                );
              })}
            </List>
          </Box>
        </Drawer>
      )}
    />
  );
};
